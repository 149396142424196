.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-img-top.post-card-img {
  max-height: 250px;
  object-fit: cover;
}

.card.about-me-class {
  background-color: transparent;
  border: 0px;
}
